import GeneralView from "./layouts/GeneralView";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import settings from './data/settings.json'
import { HelmetProvider } from "react-helmet-async";

function App() {

    const routes = settings.pages
    return ( 
        <HelmetProvider>
            <Router>
                <Routes>
                    {routes.map((route) => (
                    <Route key={route.page} path={route.path} element={<GeneralView page={route.page} meta={route.meta}/>}/> 
                    ))}
                </Routes>
            </Router>
        </HelmetProvider>
    );
}

export default App;
