import React from 'react'
import { Link } from 'react-router-dom'
import settings from '../../data/settings.json'

const Footer = () => {
    return (
        <section className='bg-dark text-white'>
            <div className='container'>
                {settings.socials &&
                    <div className="row py-3 justify-content-center">
                        {settings.socials.map((social) => (  
                            <div className="col-auto" key={social.platform} >
                                <Link to={social.url} target='_blank'>
                                    <i className={`${social.icon} text-white fs-3`}></i>
                                </Link>
                            </div>
                        ))}
                    </div>
                }
                <div className="row py-3 justify-content-center">
                    <div className="col-auto">
                        <Link to='/pages/algemene-voorwaarden' className='nav-link text-gray'>Verkoopsvoorwaarden</Link>
                    </div>
                    -
                    <div className="col-auto">
                        <Link to='/pages/contact' className='nav-link text-gray'>Contact</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer