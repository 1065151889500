import React from 'react'
import MainLoader from '../loader/MainLoader'

const SectionHeroBanner = ( { settings, contentCollection } ) => {
    return (
        <section
            className={`bg-${settings.backgroundColor} bg-overlay`}
            style={settings.backgroundImage ? {
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(${settings.backgroundImage})`,
                '--overlayopacity': settings.backgroundOverlay,
                '--overlaycolor': `var(--bs-${settings.backgroundColor})`
            } : {}}>
            <div className='container py-7'>
                <div className={`
                    row
                    hero-banner
                    ${settings && settings.gutter ? `g-${settings.gutter} ` : ''}
                    ${settings && settings.paddingY ? `py-${settings.paddingY} ` : ''}
                    ${settings && settings.hAlign ? `justify-content-${settings.hAlign} ` : ''}
                    ${settings && settings.vAlign ? `align-items-${settings.vAlign} ` : ''}
                    h-100`}>
                    {contentCollection && <MainLoader contentCollection={contentCollection}/>}                     
                </div>
            </div>
        </section>
  )
}

export default SectionHeroBanner