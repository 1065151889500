import React from 'react'
import BlockBasic from '../blocks/BlockBasic';
import BlockBasicCol from '../blocks/BlockBasicCol';

const BlockLoader = ( { item } ) => {
    const blockMap = {
        BlockBasic,
        BlockBasicCol
    }
    let Component = blockMap[item.component] || null
    if(Component) {
        return (
            <Component
                key={item.id}
                settings={item.settings}
                contentType={item.contentType}
                contentCollection={item.contentCollection}
            />
        )
    } else {
        console.log(`Compontent ${item.component} not found`)
    }
}

export default BlockLoader