import React from 'react'
import content from '../../data/content.json'
import MainLoader from './MainLoader'
import SectionLoader from './SectionLoader';
import BlockLoader from './BlockLoader';
import SnippetLoader from './SnippetLoader';

const Reference = ( { reference } ) => {
    switch(content[reference].type) {
        case 'section':
            return <SectionLoader item={content[reference]}/>;
        case 'block':
            return <BlockLoader item={content[reference]}/>;
        case 'snippet':
            return <SnippetLoader item={content[reference]}/>;
        default:
            return;
    }
}

export default Reference