import React from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

const SnippetMarkdown = ( { settings } ) => {
    return (
        <div className={`vstack gap-3`}>
            <ReactMarkdown>
                {settings.content && settings.content}
            </ReactMarkdown>
        </div>
    )
}

export default SnippetMarkdown