import React from 'react'

const SnippetImage = ( { settings, contentCollection } ) => {
    return (
        <div className={settings && settings.classes ? settings.classes : '' }>
            <div className={`${settings && settings.ratio && settings.ratio !== 'none' ? `ratio ratio-${settings.ratio}`: ''}`}>
                <img 
                    src={settings.src ? settings.src : 'https://picsum.photos/600/400'}
                    className={`${settings && settings.fit ? `object-fit-${settings.fit}` : '' }`}
                    alt={settings && settings.alt ? settings.alt : ''}
                    />
            </div>
        </div>
    )
}

export default SnippetImage