import React from 'react'
import MainLoader from '../loader/MainLoader'

const SectionBasicContainer = ( { settings, contentCollection } ) => {
    return (
        <section>
            <div className={`
                container${settings && settings.containerFluid ? '-fluid' : ''}
                ${settings && settings.classes ? settings.classes : ''}
            `}>
                <div className={`
                    row 
                    ${settings && settings.gutter ? `g-${settings.gutter} ` : ''}
                    ${settings && settings.paddingY ? `py-${settings.paddingY} ` : ''}
                    ${settings && settings.hAlign ? `justify-content-${settings.hAlign} ` : ''}
                    ${settings && settings.vAlign ? `align-items-${settings.vAlign} ` : ''}`}>
                    {contentCollection && <MainLoader contentCollection={contentCollection}/>}
                </div>
            </div>
        </section>
    )
}

export default SectionBasicContainer