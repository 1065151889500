import React from 'react'
import collections from '../../data/collections.json'
import generalSettings from '../../data/settings.json'
import pages from '../../data/pages.json'
import { Link } from 'react-router-dom'

const NavBar = ( { currentPage }) => {

    const navigation = collections[generalSettings.mainNavigation]

    return (

        <header className='bg-white border-bottom pb-lg-4'>

            <div className="container d-none d-lg-block">
                <div className="row justify-content-center">
                    <div className="col-2">
                        <Link to='/'>
                            <img src={navigation && navigation.settings && navigation.settings.logo ? navigation.settings.logo : ''} alt="Logo" className="img-fluid py-4" />
                        </Link>
                    </div>
                </div>
            </div>

            <nav className="navbar navbar-expand-lg bg-white">
                <div className="container">

                <Link className="navbar-brand d-lg-none" to='/'>
                        <img src={navigation && navigation.settings && navigation.settings.logo ? navigation.settings.logo : ''} alt="Logo" width="96" className="d-inline-block align-text-top" />
                </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarText">
                        {navigation.contentCollection &&
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                            {navigation.contentCollection.map((item, index) => {
                                if(item.type == 'list') {
                                    let href = '#'
                                    if(item.pathType == 'route' && item.path) {
                                        href = pages[item.path].slug
                                    } else if (item.pathType == 'external' && item.path) {
                                        href = item.path
                                    }
                                    return(
                                    <li key={index} className="nav-item">
                                        <Link
                                            className={`nav-link ${item.path == currentPage && `nav-link-active`}`}
                                            to={href}>
                                                {item.label}
                                        </Link>
                                    </li>
                                    )
                                }
                            })}
                        </ul>
                        }
                    </div>
                </div>
            </nav>

        </header>
    )
}

export default NavBar