import React from 'react'
import SectionHeroBanner from '../sections/SectionHeroBanner';
import SectionBasicContainer from '../sections/SectionBasicContainer';

const SectionLoader = ( { item } ) => {
    const sectionMap = {
        SectionHeroBanner,
        SectionBasicContainer
    };
    let Component = sectionMap[item.component] || null
    if(Component) {
        return (
            <Component 
                key={item.id}
                settings={item.settings}
                contentType={item.contentType}
                contentCollection={item.contentCollection}
            />
        )
    } else {
        console.log(`Compontent ${item.component} not found`)
    }
}

export default SectionLoader