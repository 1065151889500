import React from 'react'
import SnippetText from '../snippets/SnippetText'
import SnippetButton from '../snippets/SnippetButton'
import SnippetImage from '../snippets/SnippetImage';
import SnippetHTML from '../snippets/SnippetHTML';
import SnippetPricingLine from '../snippets/SnippetPricingLine';
import SnippetMarkdown from '../snippets/SnippetMarkdown';

const SnippetLoader = ( { item } ) => {
    const snippetMap = {
        SnippetText,
        SnippetButton,
        SnippetImage,
        SnippetHTML,
        SnippetPricingLine,
        SnippetMarkdown
    }
    let Component = snippetMap[item.component] || null
    if(Component) {
        if(!item.options || item.options.display !== "none") {
            return (
                <Component
                    key={item.id}
                    settings={item.settings}
                    contentType={item.contentType}
                    contentCollection={item.contentCollection}
                />
            )
        }
    } else {
        console.log(`Compontent ${item.component} not found`)
    }
}

export default SnippetLoader