import React from 'react'
import SectionLoader from './SectionLoader'
import BlockLoader from './BlockLoader';
import SnippetLoader from './SnippetLoader';
import Reference from './Reference';
import OtherLoader from './OtherLoader';

const MainLoader = ( { contentCollection } ) => {
    return(<>
        {contentCollection && contentCollection.map((item, index) => {
        switch(item.type) {
            case 'section':
                return <SectionLoader key={item.id} item={item}/>;
            case 'block':
                return <BlockLoader key={item.id} item={item}/>;
            case 'snippet':
                return <SnippetLoader key={item.id} item={item}/>;
            case 'reference':
                return <Reference key={item.id} reference={item.reference}/>;
            case 'form':
                return <OtherLoader key={item.id} item={item}/>;
            default:
                return null;
        }
    })}</>)
}

export default MainLoader