import React from 'react'
import MainLoader from '../loader/MainLoader'

const BlockBasic = ( { settings, contentCollection } ) => {
    
  return (
    <div className={`
      col${settings && settings.blockSize && `-lg-${settings.blockSize}`}
      ${settings && settings.textAlign && settings.textAlign}
      ${settings && settings.backgroundColor ? `bg-${settings.backgroundColor}` : ''}`}>
        <MainLoader contentCollection={contentCollection}/>
    </div>
  )
}

export default BlockBasic