import React from 'react'
import FormContact from '../forms/FormContact'

const OtherLoader = ( { item } ) => {
    const otherMap = {
        FormContact
    }
    let Component = otherMap[item.component] || null
    if(Component) {
        return (
            <Component
                key={item.id}
                settings={item.settings}
                contentType={item.contentType}
                contentCollection={item.contentCollection}
            />
        )
    } else {
        console.log(`Compontent ${item.component} not found`)
    }
}

export default OtherLoader